:root {
  --text: #e5e5d7;
  --dark: #0d0d0c;
  --green: #6a7349;
  --light-green: #bdcc87;
  --primary: #d9a78b;
  --secondary: #8c5230;
}

* {
  margin: 0;
  padding: 0;

  font-family: "Lato", sans-serif;
}

body {
  background: var(--dark);
}

a {
  text-decoration: none;
}

/*===================== LOGIN =====================*/
#login-container {
  height: 90vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4rem;

  margin: auto;
}

#login-container p {
  max-width: 38rem;

  font-size: 1.4rem;
  font-weight: 300;
  text-align: center;

  color: var(--text);
}

#login-button {
  display: flex;
  align-items: center;
  gap: 0.8rem;

  padding: 1rem 2rem;

  font-size: 1.6rem;
  font-weight: 300;

  color: var(--light-green);
  border: 1px solid var(--light-green);
  border-radius: 2rem;

  transition: all 0.3s ease-in-out;
}

#login-button:hover {
  cursor: pointer;
  color: var(--dark);
  background: var(--light-green);
}

/*===================== MAIN NAVIGATION =====================*/

/*===================== TOP NAVIGATION =====================*/
#top-nav {
  background: var(--dark);
  position: fixed;
  top: 0;
  width: 100%;
}

#top-nav ul {
  list-style-type: none;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.8rem;
  padding-block: 0.6rem;
}

#top-nav ul:hover {
  cursor: default;
}

#type-nav {
  height: 2.2rem;

  color: var(--primary);

  font-weight: 700;
  font-size: 1.4rem;

  border-bottom: 1px solid var(--primary);
}

.selected-type,
#type-nav li:hover {
  background: var(--primary);
  color: var(--secondary);
}

#time-range-nav {
  color: var(--light-green);

  border-bottom: 1px solid var(--light-green);
}

.selected-time-range,
#time-range-nav li:hover {
  text-decoration: underline;
}

#nav-logo {
  height: 1.8rem;

  position: fixed;
  top: 0.8rem;
  left: 1rem;
}

#logout-button {
  padding: 0.4rem 2rem;

  font-size: 1rem;

  background: none;
  color: var(--secondary);
  border: 1px solid var(--secondary);
  border-radius: 2rem;

  transition: all 0.3s ease-in-out;

  position: fixed;
  top: 0.6rem;
  right: 1rem;
}

#logout-button:hover {
  cursor: pointer;
  color: var(--dark);
  background: var(--secondary);
}

/*===================== TOP CONTAINER =====================*/
#display {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: 920px;
  margin-top: 6rem;
  margin-inline: auto;
  padding: 2rem 4rem;
  padding-bottom: 4rem;
}

#container {
  display: flex;
  flex-wrap: wrap;
  gap: 1.6rem;
}

.track {
  height: 15rem;
}

.artist {
  height: 13rem;
}

.track,
.artist,
.genre {
  color: var(--light-green);

  width: 10rem;

  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  margin: auto;
}

.track:hover,
.artist:hover,
.genre:hover {
  color: var(--primary);
}

.track img,
.artist img {
  height: 10rem;
  width: 10rem;
  object-fit: cover;
  object-position: center;

  border-radius: 1rem;
}

.info {
  display: flex;
  align-items: center;
  gap: 0.8rem;
}

.info h4,
.info h3,
.info small {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.info h2 {
  color: var(--primary);
}

.info small {
  color: var(--green);
  font-style: italic;
}

#playlist-button {
  font-size: 1.2rem;
  font-weight: 300;

  background: none;
  color: var(--light-green);
  border: 1px solid var(--light-green);
  border-radius: 2rem;

  margin-bottom: 1rem;
  padding: 0.6rem 2.2rem;

  transition: all 0.3s ease-in-out;
}

#playlist-button:hover {
  cursor: pointer;
  color: var(--dark);
  background: var(--light-green);
}

#playlist-message {
  display: none;

  margin-inline: auto;
  margin-bottom: 1rem;
  padding: 1rem 2rem;

  color: var(--text);
  border: 1px solid var(--text);
  border-radius: 0.5rem;
}

#playlist-message a {
  text-decoration: underline;
  color: var(--green);
}

/*===================== FOOTER =====================*/
footer {
  width: 100%;

  background: var(--dark);
  color: var(--primary);

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  font-weight: 300;

  padding-block: 0.6rem;
}

footer a {
  display: flex;
  align-items: center;
  gap: 0.4rem;

  color: var(--primary);
  margin-left: 1rem;
}

/*===================== SCROLLBAR =====================*/
::-webkit-scrollbar {
  background: #272724;
  width: 0.5rem;
}

::-webkit-scrollbar-track {
  background: none;
}

::-webkit-scrollbar-thumb {
  background: var(--green);
  border-radius: 1rem;
}

/*===================== RESPONSIVENESS =====================*/
@media screen and (max-width: 1023px) {
  #login-logo {
    max-width: 90%;
  }

  #nav-logo {
    display: none;
  }

  #logout-button {
    padding-inline: 1rem;
  }

  #display {
    max-width: 70vw;
  }

  #container {
    gap: 1rem;
  }

  .track,
  .artist,
  .genre {
    flex-direction: row;
    width: 100%;
  }

  .track,
  .artist {
    height: 3.6rem;
  }

  .track img,
  .artist img {
    height: 3.6rem;
    width: 3.6rem;
    border-radius: 5rem;
  }

  .info h4 {
    font-size: 1rem;
  }
  .info h3 {
    font-size: 1.2rem;
  }
  .info h2 {
    font-size: 1.4rem;
  }
  .info small {
    font-size: 0.8rem;
  }
}
